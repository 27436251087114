import { requestApi } from "./index";

const api = {
  list: async (params = "") => requestApi("/admin2021/withdraws" + params),
  approve: async (params) => requestApi("/admin2021/withdraws/approval", params),
  cancel: async (params) => requestApi("/admin2021/withdraws/cancel", params),
  statisticsByDate: async (params) =>
    requestApi("/admin2021/withdraws/statistics-by-date" + params),
};

export default api;
