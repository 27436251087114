<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalApproveWithdrawBank"
    tabindex="-1"
    aria-hidden="true"
    ref="container"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Duyệt rút tiền - user <span class="text-danger">{{ order.username }}</span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="fw-bold text-start mb-3">
            <span>ID rút tiền: </span>
            <span class="text-danger me-2">{{ order.transaction_id }}</span>
            <button class="btn btn-primary btn-xs py-1" @click="doCopy(order.transaction_id)">
              Copy
            </button>
          </div>
          <div class="fw-bold text-start mb-3">
            <span>Hãy chuyển khoản </span>
            <span class="text-danger me-2">{{ bankName }}: </span>
            <span class="text-danger me-2">{{ order.amount | formatNumber }}</span>
            <button class="btn btn-primary btn-xs py-1" @click="doCopy(order.amount)">Copy</button>
          </div>
          <div class="fw-bold text-start mb-3">
            <span>Đến số tài khoản: </span>
            <span class="text-danger me-2">{{ accountNumber }}</span>
            <button class="btn btn-primary btn-xs py-1" @click="doCopy(accountNumber)">Copy</button>
          </div>
          <div class="fw-bold text-start mb-3">
            <span>Họ và tên: </span>
            <span class="text-danger me-2">{{ accountName }}</span>
            <button class="btn btn-primary btn-xs py-1" @click="doCopy(accountName)">Copy</button>
          </div>
          <div class="fw-bold text-start mb-3">
            <span>Chi nhánh: </span>
            <span class="text-danger me-2">{{ branchName }}</span>
            <button class="btn btn-primary btn-xs py-1" @click="doCopy(branchName)">Copy</button>
          </div>
          <div class="fw-bold text-start mb-3">
            <span>Sau đó </span>
            <span class="text-danger me-2 text-capitalize">Click xác nhận để hoàn thành</span>
          </div>
          <div class="text-center">
            <button class="btn btn-success py-1 w-50" @click="approveOrder">Xác nhận</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { catchError } from "@/helpers";
import withdrawApi from "@/api/withdraw";

export default {
  name: "ApproveWithdrawBank",
  props: {
    order: Object,
  },
  data() {
    return {
      description: null,
      bankName: "",
      accountName: "",
      accountNumber: "",
      branchName: "",
    };
  },
  created() {
    console.log({ order: this.order });
  },
  watch: {
    order(value) {
      console.log(value);
      const { payment_info } = value;
      if (payment_info) {
        const info = payment_info.split(`|`);
        if (payment_info.length > 2) {
          this.bankName = info[0];
          this.accountName = info[1];
          this.accountNumber = info[2];
          this.branchName = info[3];
        }
      }
    },
  },
  methods: {
    doCopy(value) {
      let container = this.$refs.container;
      this.$copyText(value, container)
        .then(() => {
          this.$toastr.success("Copy thành công !");
        })
        .catch(() => {
          this.$toastr.success("Copy không thành công !");
        });
    },
    async approveOrder() {
      const params = {
        id: this.order.id,
      };
      const response = await withdrawApi.approve(params);
      if (response?.success) {
        this.$emit("updateOrderAfterApprove", params);
        this.$toastr.success(response?.message || "Thành công");
      } else {
        this.$toastr.error(catchError(response) || "Thất bại");
      }
    },
  },
};
</script>
