<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalCancelWithdraw"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Hoàn tiền</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="text-start" @submit.prevent="cancelOrder">
            <div class="mb-3">
              <label for="" class="form-label">Username</label>
              <input type="text" disabled :value="order.username" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Số tiền</label>
              <input
                type="number"
                disabled
                :value="order.amount | formatNumber"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Lý do hoàn</label>
              <input type="text" v-model="description" class="form-control" />
            </div>
            <div class="text-center">
              <button class="btn btn-success py-1 w-50">Xác nhận</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { catchError } from "@/helpers";
import withdrawApi from "@/api/withdraw";

export default {
  name: "CancelWithdraw",
  props: {
    order: Object,
  },
  data() {
    return {
      infos: { ...this.order },
      description: null,
    };
  },
  watch: {
    order(value) {
      this.infos = { ...value };
    },
  },
  methods: {
    async cancelOrder() {
      const params = {
        id: this.order.id,
        description: this.description,
      };
      if (!this.description) {
        this.$toastr.error("Vui lòng nhập lý do hủy rút tiền !");
        return;
      }
      const response = await withdrawApi.cancel(params);
      if (response?.success) {
        this.$toastr.success(response?.message);
        this.$emit("cancelWithdrawOrder", params);
      } else {
        this.$toastr.error(catchError(response));
      }
    },
  },
};
</script>
