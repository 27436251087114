<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-primary btn-xs me-3" @click="clickFilterBtn()">
          Load tất cả
        </button>
        <button type="button" class="btn btn-success btn-xs me-3" @click="clickFilterBtn(1)">
          Load đã duyệt
        </button>
        <button type="button" class="btn btn-warning btn-xs me-3" @click="clickFilterBtn(0)">
          Load đang xử lý
        </button>
        <button type="button" class="btn btn-danger btn-xs me-3" @click="clickFilterBtn(2)">
          Load đã hủy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WithdrawFilter",
  methods: {
    clickFilterBtn(status) {
      this.$emit("getWithdrawListByFilterBtn", status);
    },
  },
};
</script>

<style lang="scss" scoped></style>
