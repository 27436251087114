<template>
    <div class="page-content">
        <div class="container-fluid">
            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Quản lý Rút tiền</h4>
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript: void(0);">Admin Traodoicheo</a>
                                </li>
                                <li class="breadcrumb-item active">Quản lý Rút tiền</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end page title -->
            <!-- start page filter -->
            <div class="row">
                <div class="col-12">
                    <div class="card py-4">
                        <div class="container-fluid">
                            <div class="row">
                                <form @submit.prevent="getWithdrawList" class="col-md-3 mb-4">
                                    <div class="form-group">
                                        <label for="">Tìm theo Username</label>
                                        <input type="text" class="form-control" placeholder="nhập username "
                                            v-model="username" />
                                    </div>
                                </form>
                                <form class="col-md-3 mb-4">
                                    <div class="form-group">
                                        <label for="">Limit</label>
                                        <select class="form-select" v-model="limit" @change="getWithdrawList">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </form>
                                <div class="col-md-3 mb-4">
                                    <div class="form-group">
                                        <label for="">Username mua card</label>
                                        <input type="text" class="form-control" placeholder="nhập username "
                                            v-model="username_buy_card" autocomplete="off" />
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4">
                                    <div class="form-group">
                                        <label for="">Password mua card</label>
                                        <input type="password" class="form-control" placeholder="nhập password "
                                            v-model="password_buy_card" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <withdraw-filter-vue @getWithdrawListByFilterBtn="getWithdrawListByFilterBtn" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div class="form-group">
                                        <label for="">Blacklist User:</label>
                                        <input type="text" class="form-control" placeholder="nhập username "
                                            v-model="list_black_list_user" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Tổng tiền chờ duyệt</p>
                                    <h4 class="mb-0">{{ pendingWithdrawalAmount | formatNumber }}</h4>
                                </div>

                                <div class="flex-shrink-0 align-self-center">
                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i class="bx bx-copy-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Revenue</p>
                                    <h4 class="mb-0">$35, 723</h4>
                                </div>

                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-archive-in font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Average Price</p>
                                    <h4 class="mb-0">$16.2</h4>
                                </div>

                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end page filter -->
            <loading-vue v-if="loading" />
            <div class="row">
                <div class="col-md-12">
                    <datatable :stt="true" :title="'Danh sách User'" :columns="columns" :rows="manipulatedOrders"
                        :defaultPerPage="10">
                        <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
                        <td slot="tbody-tr-o" class="text-center" slot-scope="props">
                            <button :disabled="props.row.status === 1 ||
                                    props.row.status === 2 ||
                                    list_black_list_user.includes(props.row.username)
                                    " :class="`btn btn-${list_black_list_user.includes(props.row.username) ? 'dark' : 'success'
                                    } font-10 py-1 btn-xs`" data-bs-toggle="modal" data-bs-target="#modalApproveWithdrawBank"
                                @click="widthdrawCardModalOrder = props.row" v-if="props.row.method === 'bank'">
                                Duyệt tự động
                            </button>
                            <button :disabled="props.row.status === 1 ||
                                    props.row.status === 2 ||
                                    list_black_list_user.includes(props.row.username)
                                    " :class="`btn btn-${list_black_list_user.includes(props.row.username) ? 'danger' : 'success'
                                    } font-10 py-1 btn-xs`" @click="approveOrderCardAutomatic(props.row)"
                                v-if="props.row.method === 'card'">
                                Duyệt tự động
                            </button>
                            <!-- <button
                :disabled="props.row.status === 1 || props.row.status === 2"
                class="btn btn-warning font-10 py-1 btn-xs ms-1"
                data-bs-toggle="modal"
                :data-bs-target="'#modalApproveWithdrawCardManual_' + props.row.id"
                v-if="props.row.method === 'card'"
              >
                Duyệt thủ công
              </button> -->
                            <button :disabled="props.row.status === 1 || props.row.status === 2"
                                class="btn btn-danger font-10 py-1 btn-xs ms-1" data-bs-toggle="modal"
                                data-bs-target="#modalCancelWithdraw" @click="widthdrawCardModalOrder = props.row">
                                Hoàn tiền
                            </button>
                            <!-- <approve-withdraw-card-manual-vue
                :order="props.row"
                @updateOrderAfterApprove="updateOrderAfterApprove"
              /> -->
                        </td>
                    </datatable>
                </div>
            </div>
        </div>
        <cancel-withdraw-vue :order="widthdrawCardModalOrder" @cancelWithdrawOrder="cancelWithdrawOrder" />
        <approve-withdraw-bank-vue :order="widthdrawCardModalOrder"
            @updateOrderAfterApprove="updateOrderAfterApprove" />
    </div>
</template>

<script>
import withdrawApi from "@/api/withdraw";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, catchError, formatNumber } from "@/helpers/index";
import CancelWithdrawVue from "./CancelWithdraw.vue";
import ApproveWithdrawBankVue from "./ApproveWithdrawBank.vue";
import WithdrawFilterVue from "./WithdrawFilter.vue";
// import ApproveWithdrawCardManualVue from "./ApproveWithdrawCardManual.vue";

export default {
    name: "User",
    components: {
        Datatable,
        LoadingVue,
        CancelWithdrawVue,
        ApproveWithdrawBankVue,
        // ApproveWithdrawCardManualVue,
        WithdrawFilterVue,
    },
    data() {
        return {
            username: null,
            limit: 50,
            orders: [],
            list_black_list_user: [],
            loading: false,
            username_buy_card: null,
            password_buy_card: null,
            columns: [
                { label: "Username", field: "username" },
                { label: "Ref", field: "user.referral" },
                { label: "Name", field: "user.name" },
                { label: "Email", field: "user.email" },

                { label: "Mã giao dịch", field: "transaction_id" },
                { label: "Số dư", field: "balance", numeric: true },
                { label: "Method", field: "method" },
                { label: "Số tiền", field: "amount", numeric: true },
                { label: "Thông tin thanh toán", field: "payment_info" },
                { label: "Trạng thái", field: "status_name" },
                { label: "Ngày tạo", field: "created_at", timeago: true },
            ],
            widthdrawCardModalOrder: {},
        };
    },
    computed: {
        manipulatedOrders() {
            return this.orders.map((order) => {
                order.status_name = this.statusName(order.status);
                order.balance = order.user.coin;
                return order;
            });
        },
        pendingWithdrawalAmount() {
            let sum = 0;
            this.orders.forEach((x) => {
                if (x.status == 0) {
                    sum += Number(x.amount);
                }
            });
            console.log(sum);
            return sum;
        },
    },
    created() {
        this.getWithdrawList(0);
    },
    methods: {
        getCardOrderType(order) {
            const paymentInfoArray = order.payment_info.split("|");
            return paymentInfoArray[0];
        },
        async approveOrderCardAutomatic(order) {
            this.widthdrawCardModalOrder = order;
            console.log(this.widthdrawCardModalOrder);
            if (!this.username_buy_card || this.username_buy_card === "") {
                this.$toastr.error("Vui lòng nhập username mua card");
                return;
            }
            if (!this.password_buy_card || this.password_buy_card === "") {
                this.$toastr.error("Vui lòng nhập password mua card");
                return;
            }
            this.$swal
                .fire({
                    title: "Thông báo",
                    html: `Xác nhận duyệt tiền username <strong>${order.username
                        }</strong> </br>Số tiền <strong>${formatNumber(order.amount)}</strong>`,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Hủy",
                    confirmButtonText: "Xác nhận",
                    reverseButtons: true,
                })
                .then(async (result) => {
                    if (!result.value) {
                        return;
                    }
                    const params = {
                        id: order.id,
                        auto: true,
                        username: this.username_buy_card,
                        password: this.password_buy_card,
                        amount: order.amount,
                        type: this.getCardOrderType(order),
                    };
                    const response = await withdrawApi.approve(params);
                    if (response?.success) {
                        this.updateOrderAfterApprove(params);
                        this.$toastr.success(response?.message || "Thành công");
                    } else {
                        this.$toastr.error(catchError(response) || "Thất bại");
                    }
                });
        },
        getWithdrawListByFilterBtn(status) {
            this.getWithdrawList(status);
        },
        async getWithdrawList(status = "") {
            const params = {
                limit: this.limit,
                username: this.username,
            };
            if (status === 0) {
                params.status = 0;
            }
            if (status === 1) {
                params.status = 1;
            }
            if (status === 2) {
                params.status = 2;
            }
            const queries = buildParamQuery(params);
            this.loading = true;
            const response = await withdrawApi.list(queries);
            this.loading = false;
            if (response?.success) {
                this.orders = response.data;
            } else {
                this.$toastr.error(catchError(response) || "Thất bại");
            }
        },
        statusName(id) {
            switch (id) {
                case 0:
                case "0":
                    return "Đang xử lý";
                case 1:
                case "1":
                    return "Đã duyệt";
                case 2:
                case "2":
                    return "Đã hủy";
                default:
                    break;
            }
        },
        updateOrderAfterApprove(params) {
            const { id } = params;
            this.orders = this.orders.map((od) => {
                if (od.id !== id) {
                    return od;
                } else {
                    od.status = 1;
                    return od;
                }
            });
        },
        async cancelWithdrawOrder(params) {
            const { id } = params;
            this.orders = this.orders.map((od) => {
                if (od.id !== id) {
                    return od;
                } else {
                    od.status = 2;
                    return od;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
